<template>
    <div>
        <div class="d-flex justify-center" v-if="$_.isUndefined(dense)">
            <div class="pa-2">
                <v-select
                    v-model="streamingNodeLocal"
                    :items="selectStreamingNodes"
                    background-color="rfaccent2"
                    dark
                    dense
                    solo
                    hide-details
                    class="select-streaming-node"
                >
                </v-select>
            </div>
            <div class="pa-2 mt-1">
                <v-btn icon small @click="playPause" class="mr-1">
                    <v-icon v-if="!isPlaying">mdi-play</v-icon>
                    <v-icon v-else>mdi-pause</v-icon>
                </v-btn>

                <small>{{ playTime }}</small>
            </div>
        </div>

        <div class="d-flex flex-column align-center mt-6" v-else>
            <v-select
                v-model="streamingNodeLocal"
                :items="selectStreamingNodes"
                background-color="rfaccent2"
                dark
                dense
                solo
                hide-details
                class="select-streaming-node"
            ></v-select>
            <div class="d-flex justify-space-around" style="width: 100%">
                <small class="mt-1">
                    <v-icon color="red" class="mr-2 mb-1" x-small v-if="isPlaying">mdi-circle</v-icon>
                    <v-icon color="transparent" class="mr-2 mb-1" x-small v-else>mdi-circle</v-icon>
                    Live Audio</small>

                <!--                <v-icon v-if="animateSpeaker === 0" small>mdi-volume-low</v-icon>-->
                <!--                <v-icon v-else-if="animateSpeaker === 1" small>mdi-volume-medium</v-icon>-->
                <!--                <v-icon v-else-if="animateSpeaker === 2" small>mdi-volume-high</v-icon>-->

                <v-btn icon small @click="playPause" class="ml-2 mr-1">
                    <v-icon v-if="!isPlaying">mdi-play</v-icon>
                    <v-icon v-else>mdi-pause</v-icon>
                </v-btn>

                <small class="mt-1">{{ playTime }}</small>
            </div>
        </div>

        <audio ref="streaming-audio" autoplay style="width: 200px"></audio>
    </div>
</template>

<style>
    .streaming-source-name {
        font-size: 10pt;
        line-height: 1em;
    }

    .select-streaming-node {
        width: 225px;
        font-size: 10pt !important;
    }
</style>

<script>
    import Janus from 'janus-gateway-js';

    // let streamingPlugin;
    // let video;

    export default {
        name: 'AudioStream',

        props: ['dense'],

        data: () => ({
            streamingPlugin: null,
            video: null,
            isPlaying: false,
            playTime: '00:00',
            animateSpeaker: 0,
            streamingNodeLocal: 100,
            streamingNodeText: '--',
            streamingNode: 100,
            selectStreamingNodes: [
                {value: 100, text: '100: Nationwide Hub'},
                {value: 169, text: '169: Midwest Hub'},
                {value: 172, text: '172: Northeast Hub'},
                {value: 174, text: '174: Southeast Hub'},
                {value: 175, text: '175: Southern Plains Hub'},
                {value: 176, text: '176: Southwest Hub'},
                {value: 177, text: '177: Mountain Hub'},
                {value: 20400, text: '20400: Arizona Hub'},
            ],
        }),

        watch: {
            streamingNode() {
                if (this.streamingNode !== this.streamingNodeLocal.toString()) this.changeStreamingNode(this.streamingNode);
            },

            streamingNodeLocal() {
                this.changeStreamingNode(this.streamingNodeLocal);
            },

            isPlaying() {
                this.animateSpeaker = this.isPlaying ? 2 : 0;
            },

            // playTime() {
            //     if (this.isPlaying) {
            //         if (this.playTime.split(':')[1] % 2 === 0) {
            //             this.animateSpeaker = 1;
            //         } else {
            //             this.animateSpeaker = 2;
            //         }
            //     } else {
            //         this.animateSpeaker = 0;
            //     }
            // }
        },

        methods: {
            changeStreamingNode(streamingNode) {
                streamingNode = parseInt(streamingNode);
                this.streamingNodeLocal = streamingNode;
                const foundStreamingNode = this.$_.find(this.selectStreamingNodes, {value: streamingNode});
                this.streamingNodeText = foundStreamingNode ? this.$_.trim(foundStreamingNode.text.replace(streamingNode, '').replace(':', '')) : streamingNode;

                // this.streamingNode = streamingNode;
                if (typeof localStorage !== 'undefined') localStorage.setItem('mygmrs.stream.node', this.streamingNodeLocal);

                this.$emit('changeStreamingNode', streamingNode);

                if (this.streamingPlugin) {
                    this.streamingPlugin.connect(streamingNode)
                        .then(() => {
                            this.video.play();
                            // this.isPlaying = true;
                        });
                }
            },

            openAudioMenu() {
                this.audioMenu = !this.audioMenu;
            },

            initJanus() {
                const that = this;

                this.video = that.$refs['streaming-audio'];

                const janus = new Janus.Client('wss://stream.mygmrs.com/ws/', {
                    keepalive: 'true'
                });

                this.$nextTick(() => {
                    janus.createConnection('id')
                        .then(connection => {
                            connection.createSession()
                                .then(session => {
                                    session.attachPlugin('janus.plugin.streaming')
                                        .then(plugin => {
                                            this.streamingPlugin = plugin;

                                            // this.video.addEventListener('timeupdate', () => {
                                            //     let minutes = Math.floor(this.video.currentTime / 60);
                                            //     let seconds = Math.floor(this.video.currentTime - minutes * 60);
                                            //     let minuteValue;
                                            //     let secondValue;
                                            //
                                            //     if (minutes < 10) {
                                            //         minuteValue = '0' + minutes;
                                            //     } else {
                                            //         minuteValue = minutes;
                                            //     }
                                            //
                                            //     if (seconds < 10) {
                                            //         secondValue = '0' + seconds;
                                            //     } else {
                                            //         secondValue = seconds;
                                            //     }
                                            //
                                            //     this.playTime = minuteValue + ':' + secondValue;
                                            // });

                                            plugin.createPeerConnection();

                                            plugin.connect(that.streamingNodeLocal);

                                            plugin.on('pc:track:remote', event => {
                                                // console.log('pc:track:remote', event);
                                                if (event.type === 'track') {
                                                    this.video.srcObject = event.streams[0];
                                                    // this.video.autoplay = true;
                                                    // this.video.volume = 1;
                                                }
                                            });

                                            plugin.on('pc:error', event => {
                                                console.log('pc:error', event);
                                            });

                                            plugin.on('pc:close', () => {
                                                console.log('pc:close');
                                                this.isPlaying = false;
                                                this.initJanus();
                                                //lost connection to peer/server
                                            });

                                            plugin.on('hangup', event => {
                                                console.log('hangup', event);
                                            });
                                        });
                                });
                        });
                });
            },

            playPause() {
                if (this.video.paused) {
                    this.video.play();
                } else {
                    this.video.pause();
                }
            },

            stopMedia() {
                this.video.pause();
                this.video.currentTime = 0;
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.video.addEventListener('play', () => {
                    this.isPlaying = true;
                });

                this.video.addEventListener('pause', () => {
                    this.isPlaying = false;
                });

                this.video.addEventListener('timeupdate', () => {
                    let minutes = Math.floor(this.video.currentTime / 60);
                    let seconds = Math.floor(this.video.currentTime - minutes * 60);
                    let minuteValue;
                    let secondValue;

                    if (minutes < 10) {
                        minuteValue = '0' + minutes;
                    } else {
                        minuteValue = minutes;
                    }

                    if (seconds < 10) {
                        secondValue = '0' + seconds;
                    } else {
                        secondValue = seconds;
                    }

                    this.playTime = minuteValue + ':' + secondValue;
                });
            });

            this.initJanus();

            if (typeof localStorage !== 'undefined') {
                const storedStreamingNode = localStorage.getItem('mygmrs.stream.node');
                if (storedStreamingNode) this.streamingNodeLocal = storedStreamingNode;
            }
        }
    }
</script>
