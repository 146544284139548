import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"highlight"}},[_c(VToolbar,{attrs:{"dark":"","dense":"","color":"rfaccent2"}},[_c(VToolbarTitle,[_c(VIcon,[_vm._v("mdi-newspaper-variant")]),_vm._v(" News Feed ")],1)],1),_c(VCardText,[(_vm.error.state)?_c(VAlert,{attrs:{"type":"error","colored-border":"","border":"left","dense":""}},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":""}}):_vm._e()],1),_vm._l((_vm.news),function(article,index){return _c(VCard,{key:index,staticClass:"mb-5 elevation-5"},[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"flex-grow-0 flex-shrink-1"},[_c('router-link',{attrs:{"to":'/user/' + article.username}},[_c('UserAvatar',{attrs:{"user":article,"small":""}})],1)],1),_c(VCol,{staticClass:"flex-grow-1 flex-shrink-0"},[_c('h3',{staticClass:"title text-wrap"},[_vm._v(_vm._s(article.title))]),_c('div',{staticClass:"caption text-uppercase"},[_c(VIcon,{staticClass:"hidden-xs-only",attrs:{"x-small":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.moment(article.date).fromNow())+" by "),_c('router-link',{staticClass:"no-underline color-inherit",attrs:{"to":'/user/' + article.username}},[_vm._v(" "+_vm._s(article.firstName)+" "+_vm._s(article.lastName)+" ")]),_c('span',{staticClass:"hidden-sm-and-down ml-1"},[_c('router-link',{staticClass:"no-underline color-inherit",attrs:{"to":'/license/' + article.callsign}},[_vm._v(_vm._s(article.callsign))])],1)],1)])],1)],1),_c(VCardText,{staticClass:"pt-0",domProps:{"innerHTML":_vm._s(article.body)}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }