<template>
    <v-container fluid class="pa-0">
        <div class="floating-ad">
            <AdBTWR dark v-if="!user.subscribed"/>
        </div>
        <v-img
            src="@/assets/backdrop@0,5x-flip.jpg?lazy?vuetify-preload"
            height="500"
            gradient="to top right, rgba(0,0,0,.11), rgba(0,0,0,.22)"
            class="white--text"
        >
            <v-container class="mt-10">
                <Welcome/>
            </v-container>
        </v-img>

        <v-container fluidx>
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-card color="primary" dark to="/help/about-gmrs">
                        <v-card-title>
                            <h3 class="headline mx-auto">
                                <v-icon class="mr-1" size="28">mdi-library</v-icon>
                                Learn About GMRS
                            </h3>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card color="purple" dark to="/help/get-gmrs-license">
                        <v-card-title>
                            <h3 class="headline mx-auto">
                                <v-icon class="mr-1" size="28">mdi-certificate-outline</v-icon>
                                Get a GMRS License
                            </h3>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card color="green" dark to="/register">
                        <v-card-title>
                            <h3 class="headline mx-auto">
                                <v-icon class="mr-1" size="28">mdi-account-plus</v-icon>
                                Register Account
                                <small>(Free)</small>
                            </h3>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="flex-row-reverse">
                <v-col cols="12" sm="12" md="12" lg="4">
                    <v-row>
                        <v-col cols="12" class="pt-0">
<!--                            <v-card color="rforange" dark href="https://forms.gle/2usAS3nJ8M1EcxoB6">-->
<!--                                <v-card-title>-->
<!--                                    <h3 class="headline mx-auto">-->
<!--                                        <v-icon class="mr-1" size="28">mdi-clipboard-text</v-icon>-->
<!--                                        National Net Check-in-->
<!--                                    </h3>-->
<!--                                </v-card-title>-->
<!--                            </v-card>-->
                        </v-col>

                        <v-col cols="12" v-if="streamScheduled">
                            <div class="aspect-ratio">
                                <iframe
                                    src="https://www.youtube.com/embed/live_stream?channel=UCFVXx5WSU5suGQBWS9SbHNg"
                                    frameborder="0"
                                    allowfullscreen=""
                                ></iframe>
                            </div>
                        </v-col>

<!--                        <v-col cols="12" class="text-center">-->
<!--                            <v-card>-->
<!--                                <v-toolbar dark dense color="rfaccent2">-->
<!--                                    <v-toolbar-title>-->
<!--                                        <v-icon>mdi-volume-high</v-icon>-->
<!--                                        myGMRS Network Streaming Audio-->
<!--                                    </v-toolbar-title>-->
<!--                                </v-toolbar>-->

<!--                                <v-card-text>-->
<!--                                    <AudioStream-->
<!--                                        :streaming-node="streamingNode"-->
<!--                                        :select-streaming-nodes="streamingNodes"-->
<!--                                        @changeStreamingNode="changeStreamingNode"-->
<!--                                    />-->
<!--                                </v-card-text>-->
<!--                            </v-card>-->
<!--                        </v-col>-->
                    </v-row>

                    <!--                    <v-row>-->
                    <!--                        <v-col cols="12">-->
                    <!--                            <v-card-->
                    <!--                                    class="mt-4 mx-auto"-->
                    <!--                                    max-width="500"-->
                    <!--                            >-->
                    <!--                                <v-sheet-->
                    <!--                                        class="v-sheet&#45;&#45;offset mx-auto mb-5"-->
                    <!--                                        color="info"-->
                    <!--                                        elevation="5"-->
                    <!--                                        max-width="calc(100% - 32px)"-->
                    <!--                                >-->
                    <!--                                    <v-sparkline-->
                    <!--                                            :labels="stats.userGraph.labels"-->
                    <!--                                            :value="stats.userGraph.values"-->
                    <!--                                            color="white"-->
                    <!--                                            line-width="2"-->
                    <!--                                            padding="16"-->
                    <!--                                    ></v-sparkline>-->
                    <!--                                </v-sheet>-->

                    <!--                                <v-card-text class="pt-0">-->
                    <!--                                    <div class="title font-weight-light mb-2">Users Active in the Last Year</div>-->
                    <!--                                    <div class="subheading font-weight-light grey&#45;&#45;text">Data Over Last 7 Days</div>-->
                    <!--                                </v-card-text>-->
                    <!--                            </v-card>-->
                    <!--                        </v-col>-->
                    <!--                        <v-col cols="12">-->
                    <!--                            <v-card-->
                    <!--                                    class="mt-4 mx-auto"-->
                    <!--                                    max-width="500"-->
                    <!--                            >-->
                    <!--                                <v-sheet-->
                    <!--                                        class="v-sheet&#45;&#45;offset mx-auto mb-5"-->
                    <!--                                        color="info"-->
                    <!--                                        elevation="5"-->
                    <!--                                        max-width="calc(100% - 32px)"-->
                    <!--                                >-->
                    <!--                                    <v-sparkline-->
                    <!--                                            :labels="stats.repeaterGraph.labels"-->
                    <!--                                            :value="stats.repeaterGraph.values"-->
                    <!--                                            color="white"-->
                    <!--                                            line-width="2"-->
                    <!--                                            padding="16"-->

                    <!--                                    ></v-sparkline>-->
                    <!--                                </v-sheet>-->

                    <!--                                <v-card-text class="pt-0">-->
                    <!--                                    <div class="title font-weight-light mb-2">Repeaters Updated in the Last Year</div>-->
                    <!--                                    <div class="subheading font-weight-light grey&#45;&#45;text">Data Over Last 7 Days</div>-->
                    <!--                                </v-card-text>-->
                    <!--                            </v-card>-->
                    <!--                        </v-col>-->
                    <!--                    </v-row>-->
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" class="pt-0">
                    <News/>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<style>
    iframe {
        width: 100%;
    }

    .aspect-ratio {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 51%;
    }

    .aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
</style>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import Welcome from '@/components/Welcome.vue';
    import News from '@/components/News.vue';
    import axios from 'axios';
    import { useTitle } from '@vueuse/core';
    import config from '../config';

    export default {
        name: 'home',
        props: ['user'],
        components: {
            AdBTWR,
            Welcome,
            News
        },
        data: () => ({
            streamingNode: 100,
            streamingNodes: [
                {value: 100, text: '100: Nationwide Hub'},
                {value: 169, text: '169: Midwest Hub'},
                {value: 172, text: '172: Northeast Hub'},
                {value: 174, text: '174: Southeast Hub'},
                {value: 175, text: '175: Southern Plains Hub'},
                {value: 176, text: '176: Southwest Hub'},
                {value: 177, text: '177: Mountain Hub'},
                {value: 20400, text: '20400: Arizona Hub'},
            ],
            stats: {
                loading: false,
                users: null,
                repeaters: null,
                networks: null,
                states: null,
                history: [],
                userGraph: {labels: [], values: []},
                repeaterGraph: {labels: [], values: []}
            }
        }),

        mounted() {
            useTitle('myGMRS.com - GMRS Repeater Directory and Community');

            // this.getStatistics();
        },

        computed: {
            streamScheduled() {
                //@todo use a more sophisticated method like time or actually checking if a stream is scheduled via API
                return false;
                //this checks if it's the last Sunday of the month (National Net) or a Saturday (Tech Net)
                // return (this.$moment().day() === 0 && this.$moment().week() === this.$moment().endOf('month').startOf('week').week()) || this.$moment().day() === 6;
            }
        },

        methods: {
            getStatistics() {
                this.stats.loading = true;

                axios
                    .get(config.API_LOCATION + '/stats')
                    .then(response => {
                        this.stats.users = response.data.recent.users;
                        this.stats.activeUsers = response.data.recent.activeUsers;
                        this.stats.repeaters = response.data.recent.repeaters;
                        this.stats.activeRepeaters = response.data.recent.activeRepeaters;
                        this.stats.networks = response.data.recent.networks;
                        this.stats.states = response.data.recent.states;
                        this.stats.activeLicenses = response.data.recent.activeLicenses;
                        this.stats.newLicenses = response.data.recent.newLicenses;
                        this.stats.history = response.data.history;

                        this.stats.userGraph.labels = this.stats.history.map(o => {
                            return o.activeUsers || 0;
                        });

                        this.stats.userGraph.values = this.stats.history.map(o => {
                            return o.activeUsers || 0;
                        });

                        this.stats.repeaterGraph.labels = this.stats.history.map(o => {
                            return o.activeRepeaters || 0;
                        });

                        this.stats.repeaterGraph.values = this.stats.history.map(o => {
                            return o.activeRepeaters || 0;
                        });
                    })
                    .catch(err => {
                        this.user = {};
                        this.user.authenticated = false;
                        if (err.response && err.response.status !== 401) {
                            console.error(JSON.stringify(err));
                        }
                    })
                    .then(() => {
                        this.stats.loading = false;
                    });
            },
        }
    }
</script>
