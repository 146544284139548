<template>
    <v-app>
        <v-app-bar
            app
            dark
            color="rfnavy"
            clipped-right
        >
            <v-btn icon @click="menu.state = !menu.state" class="hidden-lg-and-up">
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <!--                        <v-toolbar-title>-->
            <div class="d-flex align-center">
                <router-link to="/">
                    <v-img
                        alt="myGMRS.com Logo"
                        contain
                        width="160"
                        height="41"
                        :src="require('./assets/logo.png')"
                        class="shrink mr-2"
                    />
                </router-link>
            </div>
            <!--                        </v-toolbar-title>-->

            <v-spacer></v-spacer>

            <v-toolbar-items class="hidden-md-and-down">
                <v-btn v-for="(item, i) in links" :key="i" text :to="item.link">
                    <v-icon small class="mr-1">{{ item.icon }}</v-icon>
                    {{ item.title }}
                </v-btn>

                <v-btn text href="https://shop.mygmrs.com/">
                    <v-icon small class="mr-1">mdi-cart</v-icon>
                    Shop
                </v-btn>

                <v-btn text href="https://forums.mygmrs.com/">
                    <v-icon small class="mr-1">mdi-forum</v-icon>
                    Forums
                </v-btn>

                <!--                <v-btn text to="/admin" v-if="user.authenticated && user.role === 0">-->
                <!--                    <v-icon small class="mr-1">mdi-database-lock</v-icon>-->
                <!--                    Admin-->
                <!--                </v-btn>-->


                <v-btn text class="hidden-sm-and-down" @click="showLogin()" v-if="!user.authenticated">
                    <v-icon small>mdi-login</v-icon>
                    <span class="ml-1">Sign In</span>
                </v-btn>

                <v-btn text class="hidden-sm-and-down" to="/register" v-if="!user.authenticated">
                    <v-icon small>mdi-account-plus</v-icon>
                    <span class="ml-1">Register</span>
                </v-btn>

                <v-btn text @click="logout()" v-if="user.authenticated">
                    <v-icon small class="mr-1">mdi-logout</v-icon>
                    Sign Out
                </v-btn>

                <v-list-item two-line class="hidden-sm-and-down" v-if="user.authenticated">
                    <router-link :to="'/user/' + user.username" class="avatar-link">
                        <UserAvatarList
                            :user="user"
                            :key="user.avatarUpdate"
                            small
                            ref="userAvatar"
                            v-if="user"
                        />
                    </router-link>

                    <v-list-item-content class="py-0">
                        <v-list-item-title>
                            <router-link :to="'/user/' + user.username" class="white--text no-underline">
                                {{ user.username }}
                            </router-link>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <router-link :to="'/license/' + user.callsign"
                                         class="grey--text text--lighten-1 no-underline">
                                {{ user.callsign }}
                            </router-link>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-toolbar-items>
        </v-app-bar>

        <v-navigation-drawer
            v-model="menu.state"
            app
            right
            :touchless="$vuetify.breakpoint.lgAndUp"
            :clipped="$vuetify.breakpoint.lgAndUp"
            :color="$vuetify.theme.dark ? '' : 'grey lighten-5'"
        >
            <template v-slot:prepend>
                <v-list-item two-line class="rfnavy hidden-lg-and-up dense" v-if="user.authenticated">
                    <router-link :to="'/user/' + user.username" class="avatar-link">
                        <v-list-item-avatar class="my-0" :color="hideAvatarText ? 'rfaccent2' : 'rfaccent2'">

                            <v-img
                                eager
                                aspect-ratio="1"
                                :src="'https://files.mygmrs.com/avatar/' + user.username.toLowerCase()"
                                :alt="user.username + ' Avatar'"
                                @load="hideAvatarText = true"
                                @error="hideAvatarText = false"
                                v-show="hideAvatarText"
                            ></v-img>
                            <span class="white--text avatar-text text-uppercase font-weight-light"
                                  v-if="!hideAvatarText">{{ user.callsign | splitCallsign }}</span>
                        </v-list-item-avatar>
                    </router-link>

                    <v-list-item-content class="py-0">
                        <v-list-item-title>
                            <router-link :to="'/user/' + user.username" class="white--text no-underline">
                                {{ user.username }}
                            </router-link>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <router-link
                                :to="'/license/' + user.callsign"
                                class="grey--text text--lighten-1 no-underline"
                            >
                                {{ user.callsign }}
                            </router-link>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>


                <v-list-item class="rfaccent darken-2 hidden-lg-and-up" dark v-else>
                    <v-row>
                        <v-col cols="6">
                            <v-btn small text class="hidden-lg-and-up" @click="showLogin()">
                                <span class="mr-2">Sign In</span>
                                <v-icon>mdi-login</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn small text class="hidden-lg-and-up" to="/register">
                                <span class="mr-2">Register</span>
                                <v-icon>mdi-account-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-list-item>
            </template>

            <v-list class="hidden-lg-and-up">
                <v-list-item-group color="primary">
                    <v-list-item
                        v-for="(item, i) in links"
                        :key="i"
                        :to="item.link"
                        dense
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="pr-1" size="20">{{ item.icon }}</v-icon>
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item dense href="https://shop.mygmrs.com/">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="pr-1" size="20">mdi-cart</v-icon>
                                Shop
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item dense href="https://forums.mygmrs.com/">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="pr-1" size="20">mdi-forum</v-icon>
                                Forums
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item dense @click="logout()" v-if="user.authenticated">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="pr-1" size="20">mdi-logout</v-icon>
                                Sign Out
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>


            <div class="mt-2" v-if="user.authenticated">

                <v-expansion-panels
                    v-model="expanded"
                    tile
                    class="mt-n2 control-panels"
                >
                    <v-expansion-panel
                        class="control-panel"
                    >
                        <v-expansion-panel-header
                            color="rfaccent2"
                            class="control-panel-header white--text"
                        >
                            Control Panel
                            <template v-slot:actions>
                                <v-icon color="white">
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            eager
                            class="control-panel-content"
                        >
                            <v-list nav>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        dense
                                        v-for="(item, i) in controlPanel"
                                        :key="i"
                                        :to="!item.externalLink ? item.link : null"
                                        :href="item.externalLink ? item.link : null "
                                    >

                                        <v-list-item-icon class="d-noned">
                                            <v-badge
                                                color="error"
                                                :content="unreadRequests"
                                                overlap
                                                :value2="item.text === 'My Requests' && unreadRequests > 0"
                                                :value="((config.USE_INBOX && item.text === 'Inbox') || (!config.USE_INBOX && item.text === 'My Requests')) && unreadRequests > 0"
                                            >
                                                <v-icon>{{ item.icon }}</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <!--<v-icon class="pr-1" size="20">{{ item.icon }}</v-icon>-->
                                                {{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>


                <!--                <h3 class="title mt-2 px-4 font-weight-medium">Control Panel</h3>-->
                <!--                <v-list>-->
                <!--                    <v-list-item-group color="primary">-->
                <!--                        <v-list-item-->
                <!--                            dense-->
                <!--                            v-for="(item, i) in controlPanel"-->
                <!--                            :key="i"-->
                <!--                            :to="!item.externalLink ? item.link : null"-->
                <!--                            :href="item.externalLink ? item.link : null "-->
                <!--                        >-->
                <!--                            <v-list-item-icon class="d-none">-->
                <!--                                <v-icon>{{ item.icon }}</v-icon>-->
                <!--                            </v-list-item-icon>-->
                <!--                            <v-list-item-content>-->
                <!--                                <v-list-item-title>-->
                <!--                                    <v-icon class="pr-1" size="20">{{ item.icon }}</v-icon>-->
                <!--                                    {{ item.text }}-->
                <!--                                </v-list-item-title>-->
                <!--                            </v-list-item-content>-->
                <!--                        </v-list-item>-->
                <!--                    </v-list-item-group>-->
                <!--                </v-list>-->
            </div>

            <div class="pl-4 pr-2 mt-2">
                <h3 class="title mt-2 mb-1 text-uppercasefont-weight-medium">License Lookup</h3>
                <div class="d-flex">
                    <v-form @submit.prevent="licenseSearch()">
                        <v-text-field
                            v-model="search.callsign"
                            label="GMRS Callsign"
                            solo
                            dense
                            hide-details
                            class="mr-2"
                            autocomplete="off"
                            @input="search.callsign = $_.isString(search.callsign) ? search.callsign.toUpperCase() : null"
                        >
                            <v-icon slot="append" color="primary" @click="licenseSearch()">mdi-magnify</v-icon>
                        </v-text-field>


                        <!--                        <v-autocomplete-->
                        <!--                            v-model="search.callsign"-->
                        <!--                            label="GMRS Callsign"-->
                        <!--                            placeholder="Type to Search"-->
                        <!--                            solo-->
                        <!--                            dense-->
                        <!--                            hide-details-->
                        <!--                            class="mr-2"-->
                        <!--                            autocomplete="off"-->
                        <!--                            :items="search.results"-->
                        <!--                            :loading="search.loading"-->
                        <!--                            :search-input.sync="search.query"-->
                        <!--                            hide-no-data-->
                        <!--                            xhide-selected-->
                        <!--                            item-text="combinedText"-->
                        <!--                            item-value="callSign"-->
                        <!--                        >-->
                        <!--                            <v-icon slot="append" color="primary" @click="licenseSearch()">mdi-magnify</v-icon>-->
                        <!--                        </v-autocomplete>-->
                    </v-form>
                </div>

                <div class="mb-5" v-if="config.NETWORK_ENABLED">
                    <AudioStream dense class="mr-2"/>
                </div>

                <!--                <h3 class="title text-uppercase font-weight-medium">Quick Stats</h3>-->
                <!--                <div v-if="stats.loading" class="text-center my-5">-->
                <!--                    <v-progress-circular indeterminate color="primary"></v-progress-circular>-->
                <!--                </div>-->
                <!--                <v-simple-table dense v-else>-->
                <!--                    <tr>-->
                <!--                        <td class="text-right pr-0">{{ stats.activeLicenses ? stats.activeLicenses.toLocaleString('en')-->
                <!--                            : '-' }}-->
                <!--                        </td>-->
                <!--                        <td>Active Licenses</td>-->
                <!--                    </tr>-->
                <!--                    <tr>-->
                <!--                        <td class="text-right pr-0">{{ stats.users ? stats.users.toLocaleString('en') : '-' }}</td>-->
                <!--                        <td>Registered Users</td>-->
                <!--                    </tr>-->
                <!--                    <tr>-->
                <!--                        <td class="text-right pr-0">{{ stats.repeaters ? stats.repeaters.toLocaleString('en') : '-' }}-->
                <!--                        </td>-->
                <!--                        <td>Repeaters Listed</td>-->
                <!--                    </tr>-->
                <!--                        <td class="text-right pr-0">{{ stats.states }}</td>-->
                <!--                        <td>States Covered</td>-->
                <!--                    </tr>-->
                <!--                </v-simple-table>-->

                <!--                                <div class="text-center">-->
                <!--                                    <a href="https://play.google.com/store/apps/details?id=com.mygmrs.app&utm_source=mygmrs&utm_campaign=google_play&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">-->
                <!--                                        <img alt="Get it on Google Play"-->
                <!--                                             src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"-->
                <!--                                             width="175">-->
                <!--                                    </a>-->
                <!--                                </div>-->

                <v-container class="px-0" v-if="!user.subscribed">
                    <v-row>
                        <v-col cols="12" align="center">

                            <div>
                                <span class="caption"><small>Advertisement</small></span>
                                <!--                        <v-card width="160" height="600">-->
                                <!--                            <Adsense-->
                                <!--                                    data-ad-client="ca-pub-2391363374702677"-->
                                <!--                                    data-ad-slot="4489844545"-->
                                <!--                                    data-ad-format="vertical"-->
                                <!--                            >-->
                                <!--                            </Adsense>-->
                                <a href="https://shop.mygmrs.com/collections/repeaters-and-accessories/products/retevis-rt97s-portable-gmrs-repeater?utm_source=mygmrs&utm_medium=banner&utm_campaign=rt97">
                                    <v-img
                                        :src="require('./assets/ads/RT97S-square-250.png')"
                                    />
                                </a>
                            </div>

                            <div v-if="!user.subscribed">
                                <span class="caption"><small>Advertisement</small></span>
                                <a href="https://shop.mygmrs.com/collections/gmrs-radios/products/midland-mxt400vp3-micromobile-gmrs-radio-value-pack?utm_source=mygmrs&utm_medium=banner&utm_campaign=mxt400vp3">
                                    <v-img
                                        :src="require('./assets/ads/MXT400VP3-square-250.png')"
                                    />
                                </a>
                            </div>
                            <!--                        </v-card>-->
                        </v-col>
                    </v-row>
                </v-container>

                <div class="d-flex justify-center mb-5 mr-2">
                    <v-switch
                        v-model="darkMode.value"
                        prepend-icon="mdi-weather-sunny"
                        append-icon="mdi-weather-night"
                        hide-details
                        color="light-blue lighten-4"
                    ></v-switch>
                </div>

                <p class="mb-0x text-center mr-2"><small>{{ config.VERSION }}</small></p>

                <div style="height: 56px" class="hidden-md-and-up"></div>
            </div>
        </v-navigation-drawer>

        <v-main id="mainContent">
            <router-view
                id="router-view"
                :user="user"
                @updaterequests="getUnread"
                @avatarupdate="updateAvatar"
                @reloadprofile="getUserProfile"
                @unauthorized="showLogin"
                @login="handleLogin"
                @logout="handleLogout"
                :class="$router.currentRoute.name === 'map' || $router.currentRoute.name === 'map-id' ? '' : 'pb-10'"
            ></router-view>
        </v-main>

        <v-footer
            app
            inset
            absolute
            padless
            dark
            color="almostblack"
        >

            <v-col rows="12" align="center" class="pt-2 px-1">
                <p class="mt-0 mb-0 caption text-uppercase">
                    <small>Copyright &copy; {{ currentYear }} <a href="https://repeaterfinder.com/"
                                                                 class="white--text" target="_blank">RepeaterFinder,
                        LLC</a>. Unauthorized duplication of content is strictly prohibited.</small>
                </p>

                <div>
                    <router-link to="/privacy-policy" class="caption white--text">Privacy Policy</router-link>
                    &middot;
                    <router-link to="/terms-conditions" class="caption white--text">
                        Terms and Conditions
                    </router-link>
                    &middot;
                    <a href="https://status.mygmrs.com/" target="_blank" class="caption white--text">
                        System Status
                    </a>
                </div>

                <div class="d-flex justify-space-around align-center">
                    <div class="mt-5 mx-5 hidden-md-and-down">
                        <img :src="require('./assets/flag_usa.svg')" width="50"/>

                        <div class="text-no-wrap ml-2">
                            Made in
                            <v-icon color="white" title="New Jersey">sf sf-nj</v-icon>
                        </div>
                    </div>

                    <div class="flex-grow-1 hidden-sm-and-down" style="max-width: 1200px;">
                        <div v-if="!user.subscribed">
                            <span class="caption"><small>Advertisement</small></span>
                            <Adsense
                                data-ad-client="ca-pub-2391363374702677"
                                data-ad-slot="4489844545"
                                data-ad-format="horizontal"
                                v-if="!user.subscribed"
                            ></Adsense>
                        </div>
                        <div class="d-block" v-else>
                            <v-card color="rfaccent2 darken-1" v-if="user.subscribed"
                                    class="d-flex justify-center align-center mt-4 mb-2 elevation-5">
                                <div class="pa-2" v-resize-text="{minFontSize: 8, maxFontSize: 20}">
                                    <v-icon color="red" class="mr-2">mdi-heart</v-icon>
                                    Thank you for being a Premium Member!
                                    <v-icon color="red" class="ml-2">mdi-heart</v-icon>
                                </div>
                            </v-card>
                        </div>
                    </div>

                    <div class="mx-5">
                        <v-sheet color="white" class="elevation-5 d-inline-block mt-4" shaped>
                            <v-btn
                                href="https://facebook.com/mygmrs"
                                title="Facebook"
                                target="_blank"
                                icon
                                color="#4267B2"
                            >
                                <v-icon>mdi-facebook</v-icon>
                            </v-btn>
                            <v-btn
                                href="https://twitter.com/mygmrs"
                                title="Twitter"
                                target="_blank"
                                icon
                                color="#1DA1F2"
                            >
                                <v-icon>mdi-twitter</v-icon>
                            </v-btn>
                            <v-btn
                                href="https://www.youtube.com/c/myGMRS"
                                title="YouTube"
                                target="_blank"
                                icon
                                color="#FF0000"
                            >
                                <v-icon>mdi-youtube</v-icon>
                            </v-btn>
                        </v-sheet>
                        <h4 class="caption px-2 text-no-wrap text-uppercase font-weight-regular mt-1">Like &middot;
                            Follow &middot;
                            Subscribe</h4>
                    </div>
                </div>
            </v-col>
        </v-footer>

        <v-dialog v-model="dialogs.adblocker.state" persistent attach="#mainContent" max-width="450">
            <v-card color="rfaccent2" dark>
                <v-card-title>Ad Blocker Detected</v-card-title>
                <v-card-text class="text-justify">
                    <p>Please disable your ad blocker. We offer many features to members for free, but it costs us money
                        to provide these services. Ads help cover these costs, so we ask that you disable your ad
                        blocker on this website.</p>

                    <p>If you'd like to hide the ads and still support our website, we also offer a Premium Membership
                        which disables all the advertisements on this website in addition to giving you several
                        additional features.</p>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                    <!--                    <v-btn color="rfaccent2">Learn More</v-btn>-->
                    <v-btn text class="font-weight-light" @click="dialogs.adblocker.state = false">Not Now</v-btn>
                    <v-btn color="success" to="/profile/subscriptions" @click="dialogs.adblocker.state = false">Go
                        Ad-free
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.login.state" attach="#mainContent" width="400">
            <v-card>
                <v-toolbar color="rfaccent darken-2" dark dense>
                    <v-icon small class="mr-1">mdi-lock</v-icon>
                    SIGN IN
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeLogin()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form @submit.prevent="login()">
                    <v-card-text>
                        <v-text-field v-model="dialogs.login.username" label="Username"
                                      autocomplete="username"></v-text-field>
                        <v-text-field v-model="dialogs.login.password" label="Password" type="password"
                                      autocomplete="current-password"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="info" to="/register" @click="closeLogin">
                            <v-icon class="mr-1">mdi-account-plus</v-icon>
                            Register
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit">Sign In
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<style lang="scss">
    @import "./styles/stateface.scss";

    .theme--dark.v-btn--hover::before,
    .theme--dark.v-btn--active::before {
        color: var(--v-rfaccent-darken2);
    }

    a.no-underline {
        text-decoration: none;
    }

    .v-alert--prominent .v-alert__icon:after {
        opacity: 0 !important;
    }

    .avatar-link {
        text-decoration: none !important;
    }

    .avatar-text {
        line-height: 12px !important;
        font-size: 12px;
        padding-top: 3px;
    }

    .mapboxgl-ctrl-attrib-inner a {
        color: #333333 !important;
    }

    .ql-align-left {
        text-align: left !important;
    }

    .ql-align-center {
        text-align: center !important;
    }

    .ql-align-right {
        text-align: right !important;
    }

    .ql-align-justify {
        text-align: justify !important;
    }

    .no-underline {
        text-decoration: none !important;
    }

    .control-panel.v-expansion-panel {
        background-color: inherit !important;
        box-shadow: none !important;
    }

    .control-panel-header {
        min-height: 48px !important;
    }

    .control-panels {
        box-shadow: none !important;
    }

    .control-panel-content {
        box-shadow: none !important;
    }

    .control-panel-content > .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }


</style>

<script>
    import config from './config';
    import axios from 'axios';
    import moment from 'moment';
    import md5 from 'md5';
    import UserAvatarList from '@/components/UserAvatarList.vue';
    import { detectDomAdblocker } from 'vue-adblock-detector';
    import { useDark, useTitle } from '@vueuse/core';
    import AudioStream from '@/components/AudioStream';
    import ResizeText from 'vue-resize-text';

    export default {
        components: {AudioStream, UserAvatarList},

        directives: {
            ResizeText
        },

        data: () => ({
            config: config,
            hideAvatarText: true,
            menu: {
                state: null,
            },
            expanded: 0,
            links: [
                {title: 'Home', link: '/', icon: 'mdi-home'},
                {title: 'Repeaters', link: '/repeaters', icon: 'mdi-radio-tower'},
                // {title: 'GMRS Nets', link: '/nets', icon: 'mdi-web'},
                {title: 'Map', link: '/map', icon: 'mdi-map'},
                {title: 'Contact', link: '/contact', icon: 'mdi-email'},
            ],
            controlPanel: [
                {text: 'Inbox', link: '/profile/inbox', icon: 'mdi-inbox-full'},
                {text: 'Edit Profile', link: '/profile/edit', icon: 'mdi-card-account-details-outline'},
                {text: 'Change Password', link: '/profile/password', icon: 'mdi-form-textbox-password'},
                {text: 'My Repeaters', link: '/profile/repeaters', icon: 'mdi-radio-tower'},
                {text: 'My Requests', link: '/profile/requests/repeater', icon: 'mdi-comment-question'},
                {
                    text: 'Clubs',
                    link: 'https://forums.mygmrs.com/clubs/',
                    icon: 'mdi-account-group',
                    externalLink: true
                },
                {text: 'Subscriptions', link: '/profile/subscriptions', icon: 'mdi-currency-usd'},
                {text: 'Reports', link: '/reports', icon: 'mdi-file-chart'},
            ],
            dialogs: {
                login: {
                    state: false,
                    username: null,
                    password: null
                },
                adblocker: {
                    state: false
                }
            },
            user: {
                authenticated: false,
                subscribed: false,
                subscriptionEnds: false,
                id: null,
                username: null,
                callsign: null,
                email: null,
                avatarUpdate: 0
            },
            preferences: {},
            darkMode: false,
            search: {
                loading: false,
                callsign: null,
                query: null,
                results: [],
            },
            unreadRequests: 0,
            stats: {
                loading: false,
                users: null,
                repeaters: null,
                networks: null,
                states: null,
            }
        }),

        watch: {
            // for autocomplete only
            // 'search.callsign'() {
            //     this.licenseSearch();
            // },

            async 'search.query'(val) {
                try {
                    // items have already been loaded
                    // if (this.search.results.length > 0) return;

                    // items have already been requested
                    // if (this.search.loading) return;

                    this.search.loading = true;

                    let axiosHeaders = {};
                    if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios
                        .get(config.API_LOCATION + '/licenses?limit=10&search=' + val, {
                            headers: axiosHeaders
                        });

                    response.data.items.map(o => {
                        o.combinedText = `${o.callSign}: ${o.entityName}`;
                        return o;
                    });

                    this.search.results = response.data.items;
                    this.search.loading = false;
                } catch (err) {
                    console.error(err);
                    this.search.loading = false;
                }
            }
        },

        filters: {
            splitCallsign(str) {
                const regexNew = /^[A-Z]{4}\d{3}$/i;
                const offset = regexNew.test(str) ? 4 : 3;
                return str.substring(0, offset) + '\n' + str.substring(offset);
            }
        },

        computed: {
            currentYear() {
                return moment().format('YYYY');
            }
        },

        methods: {
            licenseSearch() {
                if (this.$_.isString(this.search.callsign) && this.search.callsign.length > 0) this.$router.push({path: '/license/' + this.search.callsign});
                this.search.callsign = null;
                return false;
            },

            updateAvatar() {
                // this.$refs.userAvatar.$forceUpdate();
                this.user.avatarUpdate = Date.now();
            },

            showLogin() {
                // this.dialogs.login.state = true;
                // if (this.$vuetify.breakpoint.mdAndDown) this.menu.state = false;

                this.$router.push({name: 'login'});
            },

            closeLogin() {
                this.dialogs.login.state = false;
                this.dialogs.login.username = null;
                this.dialogs.login.password = null;
            },

            handleLogin(response) {
                this.user.authToken = response.authToken;
                localStorage.setItem('token', response.authToken);
                this.getUserProfile();
            },

            handleLogout() {
                this.clearUser();

                localStorage.removeItem('token');

                this.$router.push({name: 'login'});
            },

            login() {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .post(config.API_LOCATION + '/login', {
                        username: this.dialogs.login.username,
                        password: this.dialogs.login.password
                    }, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.success) {
                            //this.user.authenticated = (response.data.success === true);
                            this.dialogs.login.state = false;
                            this.dialogs.login.username = null;
                            this.dialogs.login.password = null;

                            this.getUserProfile();
                        }
                    })
                    .catch(err => {
                        console.error(JSON.stringify(err));
                        this.clearUser();
                    });

                return false;
            },

            logout() {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/logout', {
                        headers: axiosHeaders
                    })
                    .then(() => {

                    })
                    .catch(err => {
                        console.error(JSON.stringify(err));
                    })
                    .then(this.handleLogout);
            },

            getUserProfile() {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/userInfo', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.status === 200) {
                            //get unread indicators
                            this.getUnread();

                            this.user.authenticated = true;
                            this.user.subscribed = (response.data.SubscriptionEndDate * 1000) > Date.now();
                            this.user.subscriptionEnds = response.data.SubscriptionEndDate;
                            this.user.id = response.data.ID;
                            this.user.firstName = response.data.First_Name;
                            this.user.lastName = response.data.Last_Name;
                            this.user.username = response.data.Username;
                            this.user.email = response.data.Email;
                            this.user.gravatar = md5(response.data.Email);
                            this.user.callsign = response.data.Callsign;
                            this.user.profile = response.data.Profile;
                            this.user.role = this.$_.isNumber(response.data.Role) ? response.data.Role : 1;
                            this.user.stripeCustomer = response.data.StripeCustomer;
                            this.user.preferences = {
                                requests: response.data.Requests === 1,
                                digest: response.data.Digest === 1,
                            };

                            //@todo fix this, it's REALLY sloppy, only works on a page refresh, could potentially create multiple links. do better.
                            if (this.user.authenticated && this.user.role === 0) {
                                this.links.push({title: 'Admin', link: '/admin', icon: 'mdi-database-lock'},);
                            }
                        } else {
                            this.clearUser();
                        }
                    })
                    .catch(err => {
                        this.clearUser();
                        if (err.response && err.response.status !== 401) {
                            console.error(JSON.stringify(err));
                        }
                    })
                    .then(() => {
                        //detect ad blocker if they are not subscribed
                        detectDomAdblocker().then((detected) => {
                            if (detected && !this.user.subscribed) {
                                this.dialogs.adblocker.state = true;
                            }
                        });
                    });
            },

            clearUser() {
                this.user.authenticated = false;
                this.user.subscribed = false;
                this.user.subscriptionEnds = null;
                this.user.authToken = null;
                this.user.id = null;
                this.user.firstName = null;
                this.user.lastName = null;
                this.user.username = null;
                this.user.email = null;
                this.user.gravatar = null;
                this.user.callsign = null;
                this.user.profile = null;
                this.user.role = null;
                this.user.preferences = {};
            },

            getStatistics() {
                this.stats.loading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/stats', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.stats.activeLicenses = response.data.recent.activeLicenses;
                        this.stats.users = response.data.recent.users;
                        this.stats.repeaters = response.data.recent.repeaters;
                        this.stats.networks = response.data.recent.networks;
                        this.stats.states = response.data.recent.states;
                    })
                    .catch(err => {
                        if (err.response && err.response.status !== 401) {
                            console.error(JSON.stringify(err));
                        }
                    })
                    .then(() => {
                        this.stats.loading = false;
                    });
            },

            getUnread() {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/request/repeater?status=new&limit=1', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.unreadRequests = response.data?.info?.total;
                    })
                    .catch(err => {
                        console.error(err);
                    });
            },

            moment(date) {
                return moment(date);
            }
        },

        mounted() {
            if (localStorage.getItem('preferences')) this.preferences = JSON.parse(localStorage.getItem('preferences'));

            useTitle('myGMRS.com: GMRS Repeater Directory and Community');

            this.darkMode = useDark({
                onChanged: (dark) => {
                    this.$vuetify.theme.dark = dark;
                }
            });

            if (!this.user.authToken) this.user.authToken = localStorage.getItem('token');

            //remove Inbox from Control Panel links
            if (!config.USE_INBOX) this.controlPanel.shift();

            this.getUserProfile();

            // this.getStatistics();

            //hide the home link if we're on a small screen, the logo link will work instead
            if (this.$vuetify.breakpoint.name === 'lg') this.links.splice(0, 1);
        }
    };
</script>
