<template>
    <v-card color="highlight">
        <v-toolbar dark dense color="rfaccent2">
            <v-toolbar-title>
                <v-icon>mdi-newspaper-variant</v-icon>
                News Feed
            </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
            <v-alert type="error" colored-border border="left" dense v-if="error.state">{{ error.message }}</v-alert>

            <div class="text-center">
                <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
            </div>

            <v-card
                v-for="(article, index) in news"
                :key="index"
                class="mb-5 elevation-5"
            >
                <v-container>
                    <v-row>
                        <v-col class="flex-grow-0 flex-shrink-1">
                            <router-link :to="'/user/' + article.username">
                                <UserAvatar
                                    :user="article"
                                    small
                                />
                            </router-link>
                        </v-col>
                        <v-col class="flex-grow-1 flex-shrink-0">
                            <h3 class="title text-wrap">{{ article.title }}</h3>
                            <div class="caption text-uppercase">
                                <v-icon x-small class="hidden-xs-only">mdi-calendar</v-icon>
                                {{ moment(article.date).fromNow() }} by
                                <router-link :to="'/user/' + article.username" class="no-underline color-inherit">
                                    {{ article.firstName }} {{ article.lastName }}
                                </router-link>
                                <span class="hidden-sm-and-down ml-1">
                                    <router-link :to="'/license/' + article.callsign" class="no-underline color-inherit">{{ article.callsign }}</router-link>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-text class="pt-0" v-html="article.body"></v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<style>
    .no-underline {
        text-decoration: none;
    }

    .color-inherit {
        color: inherit !important;
    }
</style>

<script>
    import config from '../config';
    import axios from 'axios';
    import moment from 'moment';
    import md5 from 'md5';
    import UserAvatar from "./UserAvatar.vue";

    export default {
        components: {UserAvatar},

        data: () => ({
            loading: false,
            news: [],
            error: {
                state: false,
                message: null
            }
        }),

        methods: {
            fetchData() {
                this.loading = true;

                axios.get(config.API_LOCATION + '/news')
                    .then(response => {
                        this.news = _.take(response.data.news, 4);
                        this.error.state = false;
                        this.error.message = null;
                    })
                    .catch(err => {
                        console.error(err);
                        this.error.message = 'An error occurred while fetching the news articles.';
                        this.error.state = true;
                    })
                    .then(() => {
                        this.loading = false;
                    })
            },

            moment(date) {
                return moment(date);
            },

            md5(str) {
                return md5(str);
            }
        },

        mounted() {
            this.fetchData();
        }
    }
</script>
