<template>
    <v-sheet
            :color="color || 'transparent'"
            :dark="isDark"
            class="px-1 pb-2 mt-n3 mx-n3 text-center"
            id="ad-container"
    >
        <v-row justify="center" class="mx-0">
            <v-col cols="12" align="center" class="py-1">
                <div class="caption"><small>Advertisement</small></div>
                <a
                        href="https://www.buytwowayradios.com/wouxun-kg-q10g.html?utm_source=mygmrs_topbanner&utm_medium=banner&utm_campaign=mygmrs"
                        id="ad-link"
                        class="d-xs-inline d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block"
                >
                    <v-img
                            :src="require('../../assets/ads/BTWR-Q10G-468x60.gif')"
                            width="468"
                            contain
                            class="elevation-5"
                            ref="ad-btwr"
                            :style="cssVars"
                    />
                </a>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<style>
    #ad-container {

    }

    #ad-link {
        width: var(--width) !important;
    }

    .floating-ad {
        position: absolute;
        top: 12px;
        left: 0;
        /*left: calc(50% - var(--width));*/
        width: 100%;
        z-index: 1;
    }
</style>

<script>
    export default {
        props: ['color', 'light', 'dark'],

        data: () => ({
            cssVars: {}
        }),

        methods: {},

        computed: {
            isDark() {
                return !this.light && this.dark;
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.cssVars = {'--width': this.$refs['ad-btwr'].$el.clientWidth + 'px'};
            });
        }
    }
</script>
